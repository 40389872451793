<template>
    <div class="page">
        <div class="card library ws">
            <h2>Goblin Slayer Manga</h2>
            <p>Goblin Slayer is a manga adaptation by Kōsuke Kurose based on the light novel series by Kumo Kagyu. The manga began serialization in the June 2016 Issue of Monthly Big Gangan. The manga is also licensed in North America by Yen Press. As of now, ten volumes have already been published in Japanese since May 25, 2016.</p>
            <h2>Plot</h2>
            <p>A young priestess has formed her first adventuring party, but almost immediately they find themselves in distress. Luckily for her, the Goblin Slayer chose that place as his next killing grounds, a man who's dedicated his life to the extermination of all goblins, by any means necessary. And when rumors of his feats begin to circulate, there's no telling who might come calling next.</p>
            <a href="https://goblin-slayer.fandom.com/wiki/Goblin_Slayer_(Manga)">Source</a>
        </div>
        <div class="card">
            <div class="library-shelf">
                <div v-for="book in manga" v-bind:key="book.title">
                    <h3>{{ book.title }}</h3>
                    <img :src="book.image" :alt="book.title+' Book Cover'">
                    <a v-for="link in book.links" v-bind:key="link[1]" :href="link[1]" target="_blank">{{link[0]}}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import books from '@/data/book-manifest.json'

export default {
    name: 'characters',
    components: {},
    data: function () {
        return {
            manga: books.manga
        }
    }
}
</script>
